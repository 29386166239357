





import Vue from 'vue'
import { mapActions } from 'vuex'
import { FACEBOOK_SERVICE } from '@/constants/FbAutomatedRule'

export default Vue.extend({
  name: 'ConfirmEmail',
  data () {
    return {
      email: null,
      token: null
    }
  },
  mounted () {
    const url = new URL(window.location.href)
    try {
      const substr = url.pathname.match(/(\/confirm\/)(\S+)/)[2].split('/')
      this.email = substr[0]
      this.token = substr[1]
    } catch (e) {
      this.addNotification({ body: 'Invalid token field in link', type: 'danger' })
    }
    if (!this.email) {
      this.addNotification({ body: 'Invalid email field in link', type: 'danger' })
    }
    this.sendRequest()
  },
  methods: {
    ...mapActions('profile', ['sendConfirmEmailRequest']),
    ...mapActions('notifications', ['addNotification', 'addSuccessNotification']),
    sendRequest () {
      this.sendConfirmEmailRequest({
        email: this.email,
        token: this.token
      })
        .then(response => {
          this.addSuccessNotification('Success! Redirecting to application page')
          setTimeout(() => {
            this.$router.push({ name: 'AutomatedRules', params: { service: FACEBOOK_SERVICE } })
          }, 3000)
        }).catch(e => {
          if (e.response && e.response.data.errors) {
            this.addNotification({ body: e.response.data.errors[0].detail[0], type: 'danger' })
            this.$router.push({ name: 'First' })
          }
        })
    }
  }
})
